import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import mobile from "../../Asset/Icon/mobile.png";
import mail from "../../Asset/Icon/mail.png";
import map from "../../Asset/Icon/map.png";
import TextInput from "../Input/TextInput";
import Button from "../Button/Button";
import linkedIn from "../../Asset/Icon/LinkedIn.png";
import facebook from "../../Asset/Icon/facebook.png";
import instagram from "../../Asset/Icon/Instagram.png";
import { footerContactURL } from "../../API/apiList";
import { onAuthenticated } from "../../API/Axios";
import { validateEmail } from "../../utils";

import styles from "./footer.module.scss";

const initialFormData = {
  name: "",
  email: "",
  message: "",
};

function Footer() {
  const [formData, setFormData] = useState({ ...initialFormData });
  const [pageLoading, setPageLoading] = useState(false);
  const [validation, setvalidation] = useState({
    name: { show: false, message: "" },
    email: { show: false, message: "" },
    message: { show: false, message: "" },
  });
  const [formError, setFormError] = useState("");
  const [msgBool, setMsgBool] = useState(false);
  const [btnResponse, setbtnResponse] = useState(false);

  const { i18n, t } = useTranslation();

  const sendInquery = async () => {
    setMsgBool(false)
    if (
      validation.name.show ||
      validation.email.show ||
      validation.message.show ||
      formData.email === "" ||
      formData.name === "" ||
      formData.message === ""
    ) {
      setFormError(t("contactFormError"));
    } else {
      setPageLoading(true);
      setFormError("");
      const payload = {
        url: `${footerContactURL}`,
        method: "post",
        data: formData,
      };
      await onAuthenticated(payload)
        .then((apiRes) => {
          setPageLoading(false);
          
          if(apiRes.data === 'success'){
            setFormData({ ...formData, name: "", email:"", message: "" });
            setbtnResponse(true);
            setMsgBool(true);
          } else {
            setFormError(t("contactFormError"));
          }
          // console.log("got res 200 ", apiRes.data);
        })
        .catch((err) => {
          setPageLoading(false);
          setFormError('There is some issue with server. Please try again.');
          console.log(err);
        });
    }
  };

  return (
    <div className={styles.footerContainer} >
      <div className={styles.containerOne}>
        <ul className={styles.contactInfoList}>
          <li className={`${styles.contactListItem} ${i18n.language === 'he' ? styles.hewItem : styles.engItem} `}>
            <img
              src={mobile}
              alt="phone"
              className={`${styles.borderIcon} ${i18n.language === 'he' ? styles.footerHebIcon : styles.footerEngIcon}`}
            />
            <a
              href="tele:04-8889810"
              className={`${styles.footerIconLabel} ${styles.phoneLink}`}
            >
              {i18n.language === "en" && t("phoneLabel")} 04-8889810 
              {i18n.language === "he" && t("phoneLabel")}
            </a>
          </li>
          <li className={`${styles.contactListItem} ${i18n.language === 'he' ? styles.hewItem : styles.engItem}`}>
            <img
              src={mail}
              alt="mail"
              className={`${styles.borderIcon} ${i18n.language === 'he' ? styles.footerHebIcon : styles.footerEngIcon}`}
            />
            <span className={styles.footerIconLabel}>
              {i18n.language === "en" && t("emailLabel")}
              Office@ibengineer.com {i18n.language === "he" && t("emailLabel")}
            </span>
          </li>
          <li className={`${styles.contactListItem} ${i18n.language === 'he' ? styles.hewItem : styles.engItem}`}>
            <img src={map} alt="map" className={i18n.language === 'he' ? styles.footerHebIcon : styles.footerEngIcon} />
            <span className={styles.footerIconLabel}>
              {i18n.language === "en" && t("officeLabel")}
              {t("officeAddressValue")}
              {i18n.language === "he" && t("officeLabel")}
            </span>
          </li>
        </ul>
      </div>
      <div className={styles.containerTwo}>
        <TextInput
          className={`${styles.formInput} ${i18n.language === 'he' ? styles.herLang : styles.engLang}` }
          value={formData.name}
          marginBottom={16}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          placeHolder={`${t("nameLabel")}`}
          onBlur={() => {
            setvalidation({
              ...validation,
              name:
                formData.name === ""
                  ? { show: true, message: t("contactNameRequired") }
                  : { show: false, message: "" },
            });
          }}
          validation={true}
          validationData={validation.name}
        />
        <TextInput
          className={`${styles.formInput} ${i18n.language === 'he' ? styles.herLang : styles.engLang}` }
          value={formData.email}
          marginBottom={16}
          onChange={(e) => {
            setvalidation({
              ...validation,
              email: !validateEmail(e.target.value)
                ? { show: true, message: t("contactNotEmailRequired") }
                : { show: false, message: "" },
            });
            setFormData({ ...formData, email: e.target.value });
          }}
          placeHolder={`${t("footerEmailLabel")}`}
          onBlur={() => {
            if (formData.email === "") {
              setvalidation({
                ...validation,
                email: { show: true, message: t("contactEmailRequired") },
              });
            }
          }}
          validation={true}
          validationData={validation.email}
          style={i18n.language === 'he'? {direction:'rtl', textAlign:'right'}:{}}
        />
        <TextInput
          className={`${styles.formInput} ${i18n.language === 'he' ? styles.herLang : styles.engLang}` }
          value={formData.message}
          
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })
          }
          onBlur={() => {
            setvalidation({
              ...validation,
              message:
                formData.message === ""
                  ? { show: true, message: t("contactMessageRequired") }
                  : { show: false, message: "" },
            });
          }}
          style={i18n.language === 'he'? {direction:'rtl', textAlign:'right'}:{}}
          placeHolder={`${t("footerMessageLabel")}`}
          validation={true}
          validationData={validation.message}
        />
        {msgBool && <div className={styles.successMsg}>Message sent successfully.</div> }
        {formError !== "" && <div className={styles.errorText}>{formError}</div>}
        <Button
          className={styles.footerBtn}
          label={
            btnResponse ? t("contactUsSubmitBtnSuccess") : t("btnSendLabel")
          }
          disabled={ btnResponse ? true : false }
          loading={pageLoading}
          onClick={(e) => sendInquery(e)}
        />
        
      </div>
      <div className={styles.containerThree}>
        <label className={styles.containerThreeLabel} style={i18n.language === 'he' ? {direction: 'rtl'} : {direction: 'ltr'}}>
          {t("socialMediaLabel")}
        </label>
        <div className={styles.iconContainer}>
          <a target= "_blank" rel="noreferrer"
            href="https://www.facebook.com/profile.php?id=61550946452760"
            className={styles.mediaIcon}
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a className={styles.mediaIcon} target="_blank" href="/#"  rel="noreferrer">
            <img src={instagram} alt="instagram" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/ibeng/about/"
            className={styles.mediaIcon}
          >
            <img src={linkedIn} alt="facebook" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
